import Dialog from "Shared/Dialog";
import React, { useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { CDN_URL } from "utils/constants";
import { SigninForm } from "./SigninForm";
import { auth0Config } from "src/authConfig";

const StyledNav = ({ to, children }: any) => {
  return (
    <NavLink
      to={to}
      className={({ isActive }) => `text-gray-300 flex items-center w-40`}
    >
      {children}
    </NavLink>
  );
};

const PublicHeader = () => {
  const dialogRef = useRef<HTMLDialogElement>(null);
  function toggleDialog() {
    if (!dialogRef.current) return;
    dialogRef.current.hasAttribute("open")
      ? dialogRef.current.close()
      : dialogRef.current.showModal();
  }
  return (
    <>
      <nav className="w-full h-16 flex px-11 justify-between items-center sticky top-0 z-10 bg-slate-950">
        <img
          src={"https://" + CDN_URL + "/assets/logo-white.png"}
          className="w-[120px] mr-28"
          alt="hale ai"
        />
        {/* <div className="flex items-center justify-between">
          <StyledNav to="">Products</StyledNav>
          <StyledNav to="">Pricing</StyledNav>
          <StyledNav to="">About Us</StyledNav>
        </div> */}
        <div className="flex gap-2">
          {/* <button className="border text-sm font-medium relative border-white/[0.2] text-white px-4 py-2 rounded-full">
            <span>Request a Demo</span>
            <span className="absolute inset-x-0 w-1/2 mx-auto -bottom-px bg-gradient-to-r from-transparent via-blue-500 to-transparent  h-px" />
          </button> */}
          <button
            className="border text-sm font-medium relative border-white/[0.2] text-white px-4 py-2 rounded-full"
          >
            <a href={`https://${auth0Config.domain}/authorize?response_type=code&client_id=${auth0Config.clientId}&redirect_uri=${auth0Config.redirectUri}&scope=openid%20profile%20email%20offline_access&state=xyzABC123`}>
              Login
            </a>
            <span className="absolute inset-x-0 w-1/2 mx-auto -bottom-px bg-gradient-to-r from-transparent via-blue-500 to-transparent  h-px" />
          </button>
        </div>
      </nav>
      <Dialog toggleDialog={toggleDialog} ref={dialogRef}>
        <SigninForm />
      </Dialog>
    </>
  );
};

export default PublicHeader;
