import React from "react";
import "react-tooltip/dist/react-tooltip.css";
import { ITooltip, Tooltip as ReactTooltip } from "react-tooltip";
import useTooltip from "./useTooltip";

const Tooltip = ({ ...rest }: ITooltip): JSX.Element => {
  // const {isTooltipOpen, setIsTooltipOpen} = useTooltip();

  return <ReactTooltip style={{ zIndex: 40, width: 200 }} {...rest} />;
};

export default Tooltip;
