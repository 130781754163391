export const isMetricValPositive = (
  score: number,
  benchmark: number,
  comparator: string
) => {
  switch (comparator) {
    case "GREATER_THAN":
      return score > benchmark;
    case "LESS_THAN":
      return score < benchmark;
    case "LESS_THAN_EQ":
      return score <= benchmark;
    case "GREATER_THAN_EQ":
      return score >= benchmark;
    case "EQUALS":
      return score === benchmark;
    default:
      return true;
  }
};
