/**
 * @generated SignedSource<<5aa264c1da5a8357d120a8363939bb1c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ClinicianMetricComparator = "EQUALS" | "GREATER_THAN" | "GREATER_THAN_EQ" | "LESS_THAN" | "LESS_THAN_EQ" | "NOT_EQUALS" | "%future added value";
export type ClinicianMetricType = "PERCENTAGE" | "SCORE" | "%future added value";
export type ManagerDashboardMetricMetadataQuery$variables = {};
export type ManagerDashboardMetricMetadataQuery$data = {
  readonly metricsMetadata: ReadonlyArray<{
    readonly benchmarkScore: number;
    readonly comparator: ClinicianMetricComparator;
    readonly maxScore: number;
    readonly metricName: string;
    readonly metricType: ClinicianMetricType;
    readonly showTooltip: boolean;
  }> | null;
};
export type ManagerDashboardMetricMetadataQuery = {
  response: ManagerDashboardMetricMetadataQuery$data;
  variables: ManagerDashboardMetricMetadataQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ClinicianMetricMetadata",
    "kind": "LinkedField",
    "name": "metricsMetadata",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "metricName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "benchmarkScore",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "comparator",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "showTooltip",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "metricType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "maxScore",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ManagerDashboardMetricMetadataQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ManagerDashboardMetricMetadataQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "d27dca9c0a596bd82bba9acd2f8be9f9",
    "id": null,
    "metadata": {},
    "name": "ManagerDashboardMetricMetadataQuery",
    "operationKind": "query",
    "text": "query ManagerDashboardMetricMetadataQuery {\n  metricsMetadata {\n    metricName\n    benchmarkScore\n    comparator\n    showTooltip\n    metricType\n    maxScore\n  }\n}\n"
  }
};
})();

(node as any).hash = "2d342940bfba97dd4957ea8c5ae79135";

export default node;
