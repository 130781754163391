import React, { createContext, useContext, ReactNode, useEffect, useState } from "react";
import { useGlobalConfig } from "./context-creation/GlobalConfigContext";

type FeatureFlags = {
    summaryRequired: boolean;
    realtimeFeedback: boolean;
    // Add more flags as needed
};

const defaultFlags: FeatureFlags = {
    summaryRequired: false,
    realtimeFeedback: false,
};

const FeatureFlagContext = createContext<FeatureFlags>(defaultFlags);

export const FeatureFlagProvider = ({ children }: { children: ReactNode }) => {
    const { rc } = useGlobalConfig();
    const [flags, setFlags] = useState<FeatureFlags>(defaultFlags);

    useEffect(() => {
        const flags = rc.appConfigManager.getFeatureFlags();
        setFlags(flags);
    }, [rc]);

    return (
        <FeatureFlagContext.Provider value={flags}>
            {children}
        </FeatureFlagContext.Provider>
    );
};

// General hook to access all feature flags
export const useFeatureFlags = () => {
    const { rc } = useGlobalConfig();
    const flags = rc.appConfigManager.getFeatureFlags();
    return flags;
};

// Specific hook to check individual features
export const useFeatureFlag = (feature: keyof FeatureFlags) => {
    const flags = useFeatureFlags();
    return !!flags[feature];
}; 