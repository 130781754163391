import React from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";
import type { MetricsCardFragment$key } from "./__generated__/MetricsCardFragment.graphql";
import { MetricName } from "utils/constants";
import { FaRegQuestionCircle } from "react-icons/fa";
import Tooltip from "../atoms/Tooltip";
import { isMetricValPositive } from "utils/helper";
import { Typography } from "@mui/material";

interface Props {
  metricDetails: MetricsCardFragment$key;
}

export const MetricsCardFragment = graphql`
  fragment MetricsCardFragment on ClinicianMetric {
    metricName
    clinicianScore
  }
`;

const MetricsCard = (props: any) => {
  const { metadata } = props;
  const { metricName, clinicianScore } = props?.metricDetails;
  const isPositive = isMetricValPositive(
    clinicianScore,
    metadata?.benchmarkScore,
    metadata?.comparator
  );
  const backgroundColor = isPositive ? "bg-green-100" : "bg-red-100";
  const textColor = isPositive ? "text-green-500" : "text-red-500";
  const title = isPositive ? "text-green-400" : "text-red-400";
  return (
    <div
      className={`h-32 p-4 rounded-2xl ${backgroundColor} flex flex-col text-start relative`}
    >
      {/* <span className={`${title} mb-4`}>{MetricName[name]}</span> */}
      <span className={`${title} mb-2`}>{metricName}</span>
      <span className={`${textColor} text-2xl`}>
        {Number(clinicianScore).toFixed(1)}
        {metadata?.metricType === "SCORE" ? "/" + metadata.maxScore : "%"}
      </span>
      {metadata?.benchmarkScore !== null && <Typography variant="caption" className="text-gray-500">
        Cohort Average : {metadata?.benchmarkScore}{" "}
        {metadata?.metricType !== "SCORE" && "%"}
      </Typography>}
      {metadata?.showTooltip && (
        <div className="absolute bottom-3 right-3">
          <FaRegQuestionCircle
            data-tooltip-id="my-tooltip"
            data-tooltip-content="Metrics applicable to prescriber modules, not to aged care module."
            className="text-gray-500"
          />
        </div>
      )}

      <Tooltip id="my-tooltip" className="w-32" />
    </div>
  );
};

export default MetricsCard;
