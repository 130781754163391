import { useGlobalConfig } from "RunContext/context-creation/GlobalConfigContext";
import { FormikHelpers } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchUserToken } from "src/api/login";
import { formValues } from "src/components/PublicPage/PublicPage";
import { triggerRelayQuery } from "src/relay/environment";
import { user_based_headers_and_tabs } from "utils/configuraions";
import { useAuth0 } from "@auth0/auth0-react";

const UserQuery = `
  query useLoginQuery {
    user {
      userId
      orgId
      name
      role
    }
  }
`;

const ROLE_BASED_ROUTES: any = {
  CLINICIAN: "/coach/calls",
  MANAGER: "/manager",
  SIMUSER: "/coach/calls",
};



const useLogin = () => {
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const { rc } = useGlobalConfig();
  const navigate = useNavigate();
  const handleUserLogin = async (
    
    email: string,
    password: string,
    onSubmitProps?: FormikHelpers<formValues>,
    orgId: string = "default",
    token?: string
  ) => {
    setIsLoggingIn(true);
   
    fetchUserToken(email, password, orgId, token)
      .then((response) => {
        console.log(response);
        triggerRelayQuery(UserQuery, {}, rc).then((response): void => {
          rc.session.setLoggedIn(true);
          rc.session.setRole(response?.data?.user?.role);
          rc.session.setUserId(response?.data?.user?.userId);
          rc.session.setUserName(response?.data?.user?.name);
          rc.session.setOrgId(response?.data?.user?.orgId);
          const userRole = response?.data?.user?.role as keyof typeof user_based_headers_and_tabs;
          if (userRole) {
            console.log('userRole', userRole);
            navigate(ROLE_BASED_ROUTES[userRole]);
            rc.session.setUserMetadata(user_based_headers_and_tabs[userRole]);
          }
        });
       
      })
      .catch((err) => onSubmitProps?.setErrors({ password: err }))
      .finally(() => setIsLoggingIn(false));
    onSubmitProps?.setSubmitting(false);
  };

  return {
    handleUserLogin,
    isLoggingIn,
  };
};

export default useLogin;
