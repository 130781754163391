import React from "react";
import { RunContextApp } from "RunContext/RCApp";
import { GlobalConfigProvider } from "RunContext/context-creation/GlobalConfigContext";
import RelayEnvironment from "./relay/RelayEnvironment";
import { Pages } from "./Pages";
import { Auth0Provider } from '@auth0/auth0-react';
import { auth0Config } from './authConfig';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { useGlobalConfig } from "RunContext/context-creation/GlobalConfigContext";
import { triggerRelayQuery } from "./relay/environment";
import { UserQuery } from "./graphql/queries/UserQuery.graphql";
import { user_based_headers_and_tabs } from "utils/configuraions";
import { FeatureFlagProvider } from "RunContext/FeatureFlagContext";

interface AppProps {
  rc: RunContextApp;
}

const App = (props: AppProps) => {


  return (
    <Auth0Provider
      domain={auth0Config.domain}
      clientId={auth0Config.clientId}
      authorizationParams={{
        redirect_uri: 'https://agreeable-wave-09bbc821e.5.azurestaticapps.net/auth/callback',
        audience: `https://${auth0Config.domain}/api/v2/`,
        scope: "openid profile email",
        usePKCE: false,
        response_type: "code",
      }}
      useRefreshTokens={true}
      cacheLocation="localstorage"
    >
      <GlobalConfigProvider rc={props.rc}>
        <RelayEnvironment>
          <FeatureFlagProvider>
            <Pages />
          </FeatureFlagProvider>
        </RelayEnvironment>
      </GlobalConfigProvider>
    </Auth0Provider>
  );
};

export default App;
