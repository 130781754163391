import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGlobalConfig } from "src/RunContext/context-creation/GlobalConfigContext";
interface ProtectedComponentProps {
  component: any;
  requestingRole: string[];
}

const ProtectedComponent: React.FC<ProtectedComponentProps> = ({
  component: Component,
  requestingRole,
  ...rest
}) => {
  const navigate = useNavigate();
  const { rc } = useGlobalConfig();
  const { isAuthenticated, user, isLoading } = useAuth0();
  const role = localStorage.getItem("userRole");
  const isSignedIn = rc.session.getLoggedIn();
  console.log("role", role, user);
  useEffect(() => {
    if (isLoading) return;
    if (!isSignedIn || !requestingRole.includes(role || "")) {
      navigate("/");
    }
  }, [isSignedIn, isLoading]);

  return (
    <>
      {isSignedIn && requestingRole.includes(role || "") ? (
        <Component {...rest} />
      ) : (
        <></>
      )}
    </>
  );
};

export default ProtectedComponent;
