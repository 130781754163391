/**
 * @generated SignedSource<<427c5b126d5444283f4a49f223174d34>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MetricsCardFragment$data = {
  readonly clinicianScore: number;
  readonly metricName: string;
  readonly " $fragmentType": "MetricsCardFragment";
};
export type MetricsCardFragment$key = {
  readonly " $data"?: MetricsCardFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"MetricsCardFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MetricsCardFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "metricName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "clinicianScore",
      "storageKey": null
    }
  ],
  "type": "ClinicianMetric",
  "abstractKey": null
};

(node as any).hash = "93e00a29ac7740bba74162cb637edf9d";

export default node;
