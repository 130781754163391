import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useGlobalConfig } from "RunContext/context-creation/GlobalConfigContext";
import { triggerRelayQuery } from "src/relay/environment";
import { user_based_headers_and_tabs } from "utils/configuraions";
import { auth0Config } from "src/authConfig";
import useLogin from "hooks/useLogin";
import { cn } from "@/lib/utils";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { ShadowInnerIcon } from "@radix-ui/react-icons";

export function SigninForm() {

  const { rc } = useGlobalConfig();
  const { handleUserLogin, isLoggingIn } = useLogin();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  console.log(isLoggingIn);
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const orgId = process.env.REACT_APP_ORG_ID;
    handleUserLogin(email, password, undefined, orgId);
  };

  return (
    <div className="max-w-md w-full mx-auto rounded-none md:rounded-2xl p-4 md:p-8 shadow-input bg-white">
      <h2 className="font-bold text-xl text-neutral-800 dark:text-neutral-200">
        Welcome to Hale AI
      </h2>
      {/* <form className="my-8  min-w-[400px]" onSubmit={handleSubmit} id="signin">
        <LabelInputContainer className="mb-4">
          <Label htmlFor="email">Email</Label>
          <Input
            id="email"
            placeholder="Jane"
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </LabelInputContainer>
        <LabelInputContainer className="mb-4">
          <Label htmlFor="password">Password</Label>
          <Input
            id="password"
            placeholder="••••••••"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </LabelInputContainer>
        { }

        <button
          className="bg-gradient-to-br relative group/btn from-slate-950 to-slate-950  flex bg-slate-950 w-full text-white rounded-md h-10 font-medium shadow-[0px_1px_0px_0px_#ffffff40_inset,0px_-1px_0px_0px_#ffffff40_inset] dark:shadow-[0px_1px_0px_0px_var(--zinc-800)_inset,0px_-1px_0px_0px_var(--zinc-800)_inset] justify-center items-center"
          type="submit"
          disabled={isLoggingIn}
        >
          {isLoggingIn ? (
            <ShadowInnerIcon className="h-4 w-4 animate-spin" />
          ) : (
            <>
              Sign In &rarr;
              <BottomGradient />
            </>
          )}
        </button>

        <div className="bg-gradient-to-r from-transparent via-neutral-300 dark:via-neutral-700 to-transparent my-8 h-[1px] w-full" />

      
      </form> */}
      <div className="my-8">


        <a href={`https://${auth0Config.domain}/authorize?response_type=code&client_id=${auth0Config.clientId}&redirect_uri=${auth0Config.redirectUri}&scope=openid%20profile%20email%20offline_access&state=xyzABC123`}>
          Sign in using Auth0
        </a>
      </div>
    </div>
  );
}

const BottomGradient = () => {
  return (
    <>
      <span className="group-hover/btn:opacity-100 block transition duration-500 opacity-0 absolute h-px w-full -bottom-px inset-x-0 bg-gradient-to-r from-transparent via-cyan-500 to-transparent" />
      <span className="group-hover/btn:opacity-100 blur-sm block transition duration-500 opacity-0 absolute h-px w-1/2 mx-auto -bottom-px inset-x-10 bg-gradient-to-r from-transparent via-indigo-500 to-transparent" />
    </>
  );
};

const LabelInputContainer = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div className={cn("flex flex-col space-y-2 w-full", className)}>
      {children}
    </div>
  );
};