import axios from "axios";

export const TOKEN_SERVICES_SERVER = process.env.BACKEND_TOKEN_SERVICES;
export const fetchUserToken = async (
  email: string,
  password: string,
  orgId?: string,
  token?: string
) => {
  const formData = new URLSearchParams();
  email && formData.append("email", email);
  password && formData.append("password", password);
  orgId && formData.append("org_id", orgId);
  token && formData.append("token", token);

  try {
    const response = await axios.post(
      `${TOKEN_SERVICES_SERVER}/token`,
      formData,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );

    const { access_token } = await response.data;
    // Save token in http only cookie for future requests
    localStorage.setItem("access_token", access_token);

    // TODO : Save token in http cookie
    // document.cookie = `access_token=${access_token}; Secure; HttpOnly`;
  } catch (error: any) {
    console.log(error);
    throw error?.response?.data?.detail;
  }
};