import React from "react";
import { useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { triggerRelayQuery } from "src/relay/environment";
import { useGlobalConfig } from "RunContext/context-creation/GlobalConfigContext";
import { user_based_headers_and_tabs } from "utils/configuraions";
const ROLE_BASED_ROUTES: any = {
    CLINICIAN: "/coach/calls",
    MANAGER: "/manager",
    SIMUSER: "/coach/calls",
};

const UserQuery = `
  query RedirectQuery {
    user {
      userId
      orgId
      name
      role
    }
  }
`;
export const Redirect = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { rc } = useGlobalConfig();
    useEffect(() => {
        console.log("Redirecting to /");
        const code = searchParams.get('code');
        const state = searchParams.get('state');
        console.log(code, state);
        const callBackend = async () => {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_QUERY_SERVICES}/auth/callback?code=${code}&state=${state}`);
            // console.log(await response.json());//
            const data = await response.json();

            localStorage.setItem("access_token", data.access_token);
            console.log(data.access_token, response.status);

            if (response.status === 200) {
                console.log("triggerRelayQuery");
                triggerRelayQuery(UserQuery, {}, rc).then((response): void => {
                    console.log("triggerRelayQuery response", response);
                    rc.session.setLoggedIn(true);
                    rc.session.setRole(response?.data?.user?.role);
                    rc.session.setUserId(response?.data?.user?.userId);
                    rc.session.setUserName(response?.data?.user?.name);
                    rc.session.setOrgId(response?.data?.user?.orgId);
                    const userRole = response?.data?.user?.role as keyof typeof user_based_headers_and_tabs;
                    if (userRole) {
                        console.log('userRole', userRole);
                        navigate(ROLE_BASED_ROUTES[userRole]);
                        rc.session.setUserMetadata(user_based_headers_and_tabs[userRole]);
                    }
                });
            } else {
                navigate("/");
            }
        };
        callBackend();
    }, []);
    return (
        <div>
            Wait till you are redirected
        </div>
    );
};
