import yaml from 'js-yaml';
import { RunContextApp, FeatureFlags } from "../RCApp";

export class AppConfigManager {
  private clientId = "";
  private featureFlags: FeatureFlags;

  constructor(private rc: RunContextApp) {
    const url = window.location.href;
    if (url.match("^[a-zA-Zs]*$[.]audirie")) console.log(url);
    this.featureFlags = {
      summaryRequired: false,
      realtimeFeedback: false,
    };

    this.loadFeatureFlags().then((flags) => {
      this.featureFlags = flags;
      console.log('featureFlags', this.featureFlags);
    });
  }

  public async initialize(): Promise<void> {
    this.featureFlags = await this.loadFeatureFlags();
  }

  private async loadFeatureFlags(): Promise<FeatureFlags> {
    try {
      const env = process.env.NODE_ENV || 'development';
      console.log('Loading feature flags for env:', env);
      const response = await fetch(`${window.location.origin}/config/feature-flags/${env}.yaml`);
      console.log('YAML response status:', response.status);
      const yamlText = await response.text();
      console.log('YAML content:', yamlText);
      const config = yaml.load(yamlText) as { flags: FeatureFlags };
      console.log('Config:', config);
      return config.flags;
    } catch (error) {
      console.error('Error loading feature flags:', error);
      return {
        summaryRequired: false,
        realtimeFeedback: false,
      };
    }
  }

  public getFeatureFlags(): FeatureFlags {
    console.log('featureFlags', this.featureFlags);
    return this.featureFlags;
  }
}
